import { Checkbox as MuiCheckbox, FormControlLabel, Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { BREAKPOINTS } from '../../constants';

import { shouldForwardProp } from '../../utils/should-forward-prop';
import { COLORS, SPACE } from '../../constants';

const iconBreakpoints = `
  @media (min-width: ${BREAKPOINTS.XS}px) {
    width: 18px;
    height: 18px;
  }
  @media (min-width: ${BREAKPOINTS.MD}px) {
    width: 24px;
    height: 24px;
  }
`;

const Icon = styled(Box)`
  border-radius: 0;
  box-shadow: inset 0 0 0 1px #949494, inset 0 -1px 0 #949494;
  ${iconBreakpoints}
`;

const IconChecked = styled(Icon)`
  background-color: ${COLORS.BLACK};
  background-image: linear-gradient(180deg, hsla(0, 0%, 100%, 0.1), hsla(0, 0%, 100%, 0));
  &::before {
    display: block;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E");
    content: '';
    ${iconBreakpoints}
  }
`;

const StyleCheckbox = styled(MuiCheckbox)`
  cursor: pointer;
  padding: 0;
  margin: 0;
  margin-right: ${SPACE.X0};
  &:hover,
  &:focus,
  &.Mui-focusVisible {
    box-shadow: inset 0 0 0 2px ${COLORS.BRAND_BLUE_SIX};
    border-radius: 0;
  }
  .MuiIconButton-label {
    cursor: pointer;
    & input {
      cursor: pointer;
    }
  }
`;

const StyledFormControlLabel = styled(FormControlLabel, { shouldForwardProp })(({ isMobile }) => ({
  '.MuiFormControlLabel-label': {
    order: isMobile ? 1 : 2,
  },
  '.MuiButtonBase-root': {
    order: isMobile ? 2 : 1,
  },
}));

const Checkbox = ({ handleChange, label, checked = false, isMobile = false }) => (
  <StyledFormControlLabel
    isMobile={isMobile}
    control={
      <StyleCheckbox
        checkedIcon={<IconChecked />}
        icon={<Icon />}
        color="default"
        onClick={(event) => handleChange(event.target.checked)}
        checked={checked}
        tabIndex={0}
      />
    }
    label={label}
  />
);

Checkbox.propTypes = {
  isMobile: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.node.isRequired,
  checked: PropTypes.bool,
};

export default Checkbox;
