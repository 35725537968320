import { Slider as MuiSlider } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import { COLORS } from '../../constants';
import { logTrackingEvent } from '../../utils/tracking';

const TRACK_COLOR = COLORS.BRAND_BLUE_SIX;
const RAIL_COLOR = COLORS.GREY_FOUR;
const RAIL_HEIGHT = '8px';

const EVENT_TYPES = {
  onChange: 'onChange',
  onChangeCommitted: 'onChangeCommitted',
};

const StyledSlider = styled(MuiSlider)`
  &.MuiSlider-colorPrimary {
    width: 83.3%;

    .MuiSlider-thumb {
      background-color: ${COLORS.WHITE};
      box-shadow: 0 2px 4px 0px ${COLORS.GREY_SEVEN};
      border: 1px solid ${COLORS.GREY_SEVEN};
      width: 30px;
      height: 30px;

      :focus {
        box-shadow: 0 0px 12px 4px ${COLORS.BLUE_EIGHT};
      }
    }

    .MuiSlider-rail {
      background-color: ${RAIL_COLOR};
      height: ${RAIL_HEIGHT};
      opacity: 1;
    }

    .MuiSlider-track {
      background-color: ${TRACK_COLOR};
      height: ${RAIL_HEIGHT};
    }

    .MuiSlider-mark {
      display: none;

      &.MuiSlider-markActive {
        background-color: ${TRACK_COLOR};
      }
    }
  }
`;

const Slider = ({
  getAriaLabel,
  className = null,
  disableSelectingSingleValue = false,
  getAriaValueText,
  marks,
  sliderType,
  uiSelectedRange,
  updateSelectedRange,
  updateUiSelectedRange,
  ...props
}) => {
  // eslint-disable-next-line no-shadow
  const handleChange = (event, newRange, eventType, sliderType) => {
    const singleValueSelected = newRange[0] === newRange[1];
    if (disableSelectingSingleValue && singleValueSelected) {
      return;
    }

    if (eventType === EVENT_TYPES.onChange) {
      updateUiSelectedRange(newRange);
    } else if (eventType === EVENT_TYPES.onChangeCommitted) {
      logTrackingEvent({
        event: 'adjustSlider',
        sliderType,
        sliderRange: `${_.min(newRange)} - ${_.max(newRange)}`,
      });
      updateSelectedRange(newRange);
    }
  };

  return (
    <StyledSlider
      value={uiSelectedRange}
      onChange={(event, newRange) => handleChange(event, newRange, EVENT_TYPES.onChange)}
      onChangeCommitted={(event, newRange) => handleChange(event, newRange, EVENT_TYPES.onChangeCommitted, sliderType)}
      min={_.first(marks).value}
      max={_.last(marks).value}
      marks={marks}
      step={null}
      className={className}
      getAriaValueText={getAriaValueText}
      getAriaLabel={getAriaLabel}
      {...props}
    />
  );
};

Slider.propTypes = {
  getAriaLabel: PropTypes.func.isRequired,
  className: PropTypes.string,
  disableSelectingSingleValue: PropTypes.bool,
  getAriaValueText: PropTypes.func.isRequired,
  marks: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    })
  ).isRequired,
  sliderType: PropTypes.string.isRequired,
  uiSelectedRange: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  updateSelectedRange: PropTypes.func.isRequired,
  updateUiSelectedRange: PropTypes.func.isRequired,
};

export default Slider;
export { StyledSlider };
