import React from 'react';
import MuiTooltip from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import styled from '@emotion/styled';
import MuiIconButton from '@mui/material/IconButton';

import IconWithBackground from '../icon-with-background';
import { iconNames } from '../icons';
import { COLORS, SPACE } from '../../constants';

const IconButton = styled(MuiIconButton)`
  &.MuiButtonBase-root {
    padding: 0;
  }
  &.MuiIconButton-root > :hover {
    box-shadow: 0 0px 8px 2px ${COLORS.BLUE_EIGHT};
  }
  &.MuiIconButton-root > .Mui-focusVisible {
    box-shadow: 0 0px 8px 2px ${COLORS.BLUE_EIGHT};
  }
`;

const Tooltip = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <MuiTooltip
        arrow
        onClose={handleTooltipClose}
        open={open}
        aria-expanded={open}
        aria-describedby={null}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        slotProps={{
          tooltip: {
            sx: {
              maxWidth: '200px',
              padding: SPACE.X0,
              borderRadius: '0px',
              margin: '0px !important',
            },
          },
          arrow: {
            sx: {
              fontSize: '1rem',
            },
          },
        }}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14], // Modify the vertical margin (default is 24px)
              },
            },
          ],
        }}
        {...props}
      >
        <IconButton onClick={handleTooltipOpen} aria-label="tooltip" disableFocusRipple disableRipple size="large">
          <IconWithBackground {...{ iconName: iconNames.questionMark, backgroundColor: COLORS.BRAND_YELLOW_SEVEN }} />
        </IconButton>
      </MuiTooltip>
    </ClickAwayListener>
  );
};

export default Tooltip;
