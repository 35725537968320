import styled from '@emotion/styled';

import { BOX_SHADOW, BREAKPOINTS, COLORS, GLOBAL, SPACE } from '../../constants';
import Slider from '../slider';
import { H5, H7, SmallStyles } from '../type';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${SPACE.X3} auto;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.WHITE};
  width: 100%;
  box-shadow: ${BOX_SHADOW.DEFAULT};
  border-radius: 0;
  padding: 0 ${SPACE.X1};
`;

const Header = styled(H5)`
  margin-bottom: ${SPACE.X1};
  letter-spacing: 0;
`;

const StyledHeading = styled(H7)`
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
  margin-top: ${SPACE.X2};
  margin-bottom: ${SPACE.X1};
`;

const StyledHeadingWithTopMargin = styled(StyledHeading)`
  margin-top: ${SPACE.X3};
  @media (min-width: ${BREAKPOINTS.MD}px) {
    margin-top: ${SPACE.X2};
  }
`;

const StyledIcon = styled.div`
  margin-left: ${SPACE.X0};
  cursor: pointer;
`;

const LifestylePreferenceWrapper = styled.div`
  display: flex;
  border: 1px solid ${COLORS.GREY_FOUR};
  border-radius: 0;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACE.X1};

  .MuiFormControlLabel-label {
    ${SmallStyles}
  }

  .MuiFormControlLabel-root {
    margin-left: 0;
  }
`;

const StyledSlider = styled(Slider)`
  margin-top: ${SPACE.X1};
  @media (min-width: ${BREAKPOINTS.MD}px) {
    margin-top: ${SPACE.X0};
  }
`;

export {
  Header,
  InnerWrapper,
  Wrapper,
  StyledHeading,
  StyledHeadingWithTopMargin,
  StyledIcon,
  LifestylePreferenceWrapper,
  StyledSlider,
};
