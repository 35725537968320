import { Container, Grid } from '@mui/material';
import React, { useRef } from 'react';

import MakeModelTabPanel from './make-model-tab-panel';
import NumberPlateTabPanel from './number-plate-tab-panel';
import { Header, StyledTab, StyledTabs, TabsWrapper, Wrapper } from './styles';

const TAB_INDEXES = { MAKE_MODEL: 0, NUMBER_PLATE: 1 };

const Search = () => {
  const [selectedTab, setSelectedTab] = React.useState(TAB_INDEXES.MAKE_MODEL);
  const searchTopRef = useRef(null);

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center">
        <Grid item {...{ xs: 12, md: 8, lg: 6 }}>
          <Wrapper ref={searchTopRef}>
            <Header as="h2">Search By</Header>
            <TabsWrapper>
              <StyledTabs value={selectedTab} onChange={(event, newSelectedTab) => setSelectedTab(newSelectedTab)}>
                <StyledTab label="Make and Model" />
                <StyledTab label="Number Plate" />
              </StyledTabs>
              <Grid container justifyContent="center">
                <Grid item {...{ xs: 10, md: 9, lg: 8 }}>
                  <MakeModelTabPanel searchTopRef={searchTopRef} hidden={selectedTab !== TAB_INDEXES.MAKE_MODEL} />
                  <NumberPlateTabPanel hidden={selectedTab !== TAB_INDEXES.NUMBER_PLATE} />
                </Grid>
              </Grid>
            </TabsWrapper>
          </Wrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Search;
export { TAB_INDEXES };
