import { Box, Container, Grid } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { LIFESTYLE_PREFERENCES, SPACE } from '../../constants';
import { getSliderAriaLabelArray } from '../../utils/get-slider-aria-label-array';
import { useGutterSpacing } from '../../hooks/use-gutter-spacing';
import { PrimaryButton } from '../button';
import Checkbox from '../checkbox';
import Tooltip from '../tooltip';
import { H4 } from '../type';

import { tooltipCopy } from './tooltip-copy';
import BodyStyleSelectionGrid from './body-style-selection-grid';
import { priceRangeSliderMarks } from './utils/price-range-slider-marks';
import {
  Header,
  InnerWrapper,
  LifestylePreferenceWrapper,
  StyledHeading,
  StyledHeadingWithTopMargin,
  StyledIcon,
  StyledSlider,
  Wrapper,
} from './styles';
import { getFormattedPriceRange } from './utils/build-price-range-heading-text';

const updateLifestyleState = (selectedLifestylePreferences, lifestylePreference, filterActions) => {
  if (!_.includes(selectedLifestylePreferences, lifestylePreference)) {
    filterActions.addLifestyle(lifestylePreference);
  } else {
    filterActions.removeLifestyle(lifestylePreference);
  }
};

const LifestylePreference = (lifestylePreferenceName, tooltip, handleChange, body, lifestyleState) => {
  return (
    <Grid item {...{ xs: 12, md: 6 }}>
      <LifestylePreferenceWrapper>
        <Checkbox
          disableRipple
          handleChange={handleChange}
          label={body}
          checked={_.includes(lifestyleState, lifestylePreferenceName)}
        />
        <StyledIcon>
          <Tooltip title={tooltip} />
        </StyledIcon>
      </LifestylePreferenceWrapper>{' '}
    </Grid>
  );
};

const DiscoveryQuestionsInput = ({ filterActions, filterState, submitInput }) => {
  const { selectedBodyStyles, selectedLifestyles, uiSelectedPriceRange } = filterState;
  const { updateSelectedBodyStyles, updateSelectedPriceRange, updateUiSelectedPriceRange } = filterActions;

  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center">
        <Grid item {...{ xs: 12, md: 8, lg: 6 }}>
          <Wrapper>
            <Header as="h2">Browse safe cars on any budget</Header>
            <InnerWrapper>
              <StyledHeading id="price-range-slider">Set your budget range:</StyledHeading>
              <H4>{getFormattedPriceRange(uiSelectedPriceRange, priceRangeSliderMarks)}</H4>
              <StyledSlider
                // @TODO: Replace the Test ID with an ARIA label for better a11y
                data-testid="price-range-slider"
                {...{
                  getAriaLabel: (index) => getSliderAriaLabelArray('Budget')[index],
                  marks: priceRangeSliderMarks,
                  uiSelectedRange: uiSelectedPriceRange,
                  updateUiSelectedRange: updateUiSelectedPriceRange,
                  updateSelectedRange: updateSelectedPriceRange,
                  sliderType: 'budget',
                  disableSelectingSingleValue: true,
                  getAriaValueText: (value) => `$${value}`,
                }}
              />
              <StyledHeadingWithTopMargin>Select your preferred body types:</StyledHeadingWithTopMargin>
              <Box mt={SPACE.X1} />
              <BodyStyleSelectionGrid {...{ updateStateBodyStyles: updateSelectedBodyStyles, selectedBodyStyles }} />
              <StyledHeadingWithTopMargin>Select your lifestyle preferences:</StyledHeadingWithTopMargin>
              <Box mt={SPACE.X1} />
              <Grid container spacing={useGutterSpacing()} justifyContent="center" direction="row">
                {LifestylePreference(
                  LIFESTYLE_PREFERENCES.pPlaterApproved,
                  tooltipCopy.pPlaterApproved,
                  () => updateLifestyleState(selectedLifestyles, LIFESTYLE_PREFERENCES.pPlaterApproved, filterActions),
                  <>
                    <strong>P-plater approved</strong> (Victoria only)
                  </>,
                  selectedLifestyles
                )}
                {LifestylePreference(
                  LIFESTYLE_PREFERENCES.safeCarsForFamilies,
                  tooltipCopy.safeCarsForFamilies,
                  () =>
                    updateLifestyleState(selectedLifestyles, LIFESTYLE_PREFERENCES.safeCarsForFamilies, filterActions),
                  <strong>Safe cars for families</strong>,
                  selectedLifestyles
                )}
              </Grid>
              <Box mt={SPACE.X2} />
              <Grid container spacing={useGutterSpacing()} justifyContent="center" direction="row">
                <Grid item {...{ xs: 12, md: 10 }}>
                  <PrimaryButton
                    fullWidth
                    {...{
                      isLoading,
                      onClick: () => {
                        setIsLoading(true);
                        submitInput(filterState);
                      },
                    }}
                  >
                    Browse safe options
                  </PrimaryButton>
                </Grid>
              </Grid>
              <Box mt={SPACE.X2} />
            </InnerWrapper>
          </Wrapper>
        </Grid>
      </Grid>
    </Container>
  );
};

DiscoveryQuestionsInput.propTypes = {
  filterActions: PropTypes.shape({
    addLifestyle: PropTypes.func.isRequired,
    removeLifestyle: PropTypes.func.isRequired,
    updateSelectedBodyStyles: PropTypes.func.isRequired,
    updateSelectedPriceRange: PropTypes.func.isRequired,
    updateUiSelectedPriceRange: PropTypes.func.isRequired,
  }).isRequired,
  filterState: PropTypes.shape({
    selectedBodyStyles: PropTypes.arrayOf(String).isRequired,
    selectedLifestyles: PropTypes.arrayOf(String).isRequired,
    uiSelectedPriceRange: PropTypes.arrayOf(Number).isRequired,
  }).isRequired,
  submitInput: PropTypes.func.isRequired,
};

export default DiscoveryQuestionsInput;
export { updateLifestyleState };
